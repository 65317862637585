<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-flex" :class="{'uk-margin': bannerInfo.length > 3}">
            <div class="uk-width-expand">
              <p v-if="bannerInfo.length <= 3" class="uk-text-danger uk-margin-small-top">Jumlah banner yang aktif kurang dari 4, Mohon tambahkan banner yang aktif</p>
            </div>
            <div class="uk-width-auto uk-text-right">
              <button class="uk-button uk-button-primary uk-border-rounded" type="button" @click="showModal('mainModal')">Create</button>
            </div>
        </div>
        <div class="uk-card uk-card-default">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <tr class="uk-text-left">
                            <th>Title</th>
                            <th>URL</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th class="uk-text-center">Image</th>
                            <th class="uk-text-center">Status</th>
                            <th class="uk-width-auto uk-text-center">Action</th>
                        </tr>
                    </thead>
                    <loading-table v-if="isLoading" :colspan="6"/>
                    <tbody v-else-if="page_data.docs.length>0">
                        <tr v-for="(data, i) in page_data.docs" :key="i" class="uk-text-left">
                            <td>{{preview(data.title)}}</td>
                            <td>{{data.url}}</td>
                            <td>{{formatDate(data.start_date)}}</td>
                            <td>{{formatDate(data.end_date)}}</td>
                            <td class="uk-text-center">
                                <img :src="`${images}/check-true.svg`" style="width:20px" v-if="dataExist(data.image)">
                                <img :src="`${images}/check-false.svg`" style="width:20px" v-else>
                            </td>
                            <td class="uk-text-center">
                              <span class="uk-label uk-label-info" v-if="expired(data) === 'expired'">Expired</span>
                              <span class="uk-label uk-label-success" v-else-if="data.active">Active</span>
                                <span class="uk-label uk-label-danger" v-else>Inactive</span>
                            </td>
                            <td class="uk-text-center">
                                <button class="uk-button uk-button-small uk-button-default" type="button" >Actions</button>
                                <div uk-dropdown="mode: click">
                                    <ul class="uk-nav uk-dropdown-nav uk-text-left">
                                        <li><a @click="showModal('mainModal', data, true)">Edit</a></li>
                                        <li class="uk-nav-divider"></li>
                                        <li><a @click="showModal('deleteModal', data)">Delete</a></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="6"/>
                </table>
            </div>
            <pagination
                :total-data="page_data.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>
        <div id="mainModal" class="mainModal" uk-modal esc-close="false" bg-close="false">
          <div class="uk-modal-dialog">
            <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('mainModal')"></button>
            <div class="uk-modal-header">
              <h2 v-if="isEdit" class="uk-modal-title">Edit Form</h2>
              <h2 v-else class="uk-modal-title">Create Form</h2>
            </div>
            <div class="uk-modal-body">
              <div class="uk-margin">
                <label class="uk-form-label">Title <b class="uk-text-danger">*</b></label>
                <input
                    class="uk-input"
                    :class="{'uk-form-danger': errors.has('title')}"
                    name="title"
                    type="text"
                    v-model="form.title"
                    v-validate="'required'">
                <span class="uk-text-small uk-text-danger" v-show="errors.has('title')">{{ errors.first('title') }}</span>
              </div>
              <div class="uk-margin">
                <label class="uk-form-label">URL</label>
                <input
                    class="uk-input"
                    name="url"
                    type="text"
                    v-model="form.url">
                <span class="uk-text-small uk-text-danger" v-show="errors.has('url')">{{ errors.first('url') }}</span>
              </div>
              <div class="uk-margin">
                <label class="uk-form-label">Start Date <b class="uk-text-danger">*</b></label>
                <datetime
                    v-model="form.start_date"
                    :class="{'uk-form-danger': errors.has('start_date')}"
                    name="start_date"
                    v-validate="'required'">
                </datetime>
                <span class="uk-text-small uk-text-danger" v-show="errors.has('start_date')">{{ errors.first('start_date') }}</span>
              </div>
              <div class="uk-margin">
                <label class="uk-form-label">End Date <b class="uk-text-danger">*</b></label>
                <datetime
                    v-model="form.end_date"
                    :class="{'uk-form-danger': errors.has('end_date')}"
                    name="end_date"
                    v-validate="'required'">
                </datetime>
                <span class="uk-text-small uk-text-danger" v-show="errors.has('end_date')">{{ errors.first('end_date') }}</span>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label uk-margin-right">Image <b class="uk-text-danger">*</b></label><br>
                <img v-if="form.image && form.image != ''" :src="`${form.image}`" alt="" class="uk-margin-bottom" width="200">
                    <input
                        name="image"
                        type="file"
                        ref="image"
                        class="uk-input"
                        placeholder="Upload Image">
              </div>
              <div class="uk-margin">
                  <input class="uk-checkbox" type="checkbox" v-model="form.active"> Active
              </div>
            </div>
            <div class="uk-modal-footer uk-text-right">
              <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
              <button v-else class="uk-button uk-button-primary uk-border-rounded" type="button" @click="sendForm">Save</button>
            </div>
          </div>
        </div>
        <div id="deleteModal" class="uk-flex-top" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('deleteModal')"></button>
                <div class="uk-modal-header"><b>Are you sure to delete this data ?</b></div>
                <div class="uk-modal-body" >
                    <span>Title: {{form.title}}</span><br>
                    <span>URL: {{form.url}}</span><br>
                    <span>Start Date: {{form.start_date}}</span><br>
                    <span>End Date: {{form.end_date}}</span><br>
                    <img v-if="form.image && form.image != ''" :src="`${form.image}`" alt="" width="200">
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
                    <button v-else class="uk-button uk-button-danger uk-border-rounded" type="button" @click="deleteData">Delete</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import LoadingTable from '@/components/globals/tables/Loading';
import Pagination from '@/components/globals/Pagination';
import { PREFIX_IMAGE } from '@/utils/constant';
import formatter from "@/utils/formatter";
import { Datetime } from 'vue-datetime';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import moment from 'moment';

moment.locale('id');

export default {
    data(){
        return {
            today: moment(),
            bannerInfo: [],
            images: PREFIX_IMAGE,
            meta: {
                limit: 50,
                page: 1
            },
            form: {
                title: '',
                url: '',
                start_date: null,
                end_date: null,
                active: true,
                image: null
            },
            page_data: {docs: [], totalDocs: 0},
            isLoading: true,
            isEdit: false
        };
    },
    components: {
        EmptyTable,
        Pagination,
        LoadingTable,
        Datetime
    },
    watch: {
        meta() {
            this.getInitPageData();
        },
    },
    async mounted() {
        await this.getInitPageData();
    },
    beforeMount() {
        if (window.UIkit.modal('.mainModal')) window.UIkit.modal('.mainModal').$destroy(true);
    },
    methods: {
        ...mapActions({
            getBanner: 'marketing/getBanner',
            showBanner: 'marketing/showBanner',
            deleteBanner: 'marketing/deleteBanner',
            createBanner: 'marketing/createBanner',
            updateBanner: 'marketing/updateBanner',
            uploadImageApi: 'file/uploadImage'
        }),
        expired(item) {
            if (this.today.isBetween(item.start_date, item.end_date)) {
                return false;
            } else{
                return 'expired';
            }
        },
        async getInitPageData(){
            this.isLoading = true;
            this.page_data = await this.getBanner(this.meta);
            this.isLoading = false;
            this.bannerInfo = this.page_data.docs.filter(x => x.active === true && this.today.isBetween(x.start_date, x.end_date));
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        formatDate(date) {
            return formatter.dateComplete(date);
        },
        preview(data) {
            if (data == null) {
                return '-';
            }
            if (data.length > 26) {
                return data.substring(0,26)+'...';
            } else {
                return data;
            }
        },
        dataExist(data) {
            if (!data) {
                return false;
            } else if (data.length < 1) {
                return false;
            } else {
                return true;
            }
        },
        resetForm() {
            this.form = {
                title: '',
                url: '',
                start_date: null,
                end_date: null,
                active: true,
                image: null
            };
            this.isEdit = false;
            this.$refs.image.value = '';
            this.$validator.reset();
        },
        async showModal(modal, data=null, edit=false){
            switch (modal) {
            case 'mainModal':
                if (data) {
                    this.form = data;
                    delete this.form._id;
                }
                if (edit) {
                    this.isEdit = true;
                }
                await window.UIkit.modal('#mainModal').show();
                break;
            case 'deleteModal':
                if (data) {
                    this.form = data;
                }
                await window.UIkit.modal('#deleteModal').show();
                break;
            }
        },
        async hideModal(modal){
            switch (modal) {
            case 'mainModal':
                await window.UIkit.modal('#mainModal').hide();
                this.resetForm();
                break;
            case 'deleteModal':
                await window.UIkit.modal('#deleteModal').hide();
                this.resetForm();
                break;
            }
        },
        async sendForm() {
            try {
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                this.isLoading = true;

                await this.uploadImage();

                const response = this.isEdit
                    ? await this.updateBanner(this.form)
                    : await this.createBanner(this.form);

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('Saved!');
                    this.getInitPageData();
                    this.hideModal('mainModal');
                } else {
                    notificationDanger('Failed to save!');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        async uploadImage() {
            let files = this.$refs.image.files;
            let formData = new FormData();

            for( let i = 0; i < files.length; i++) {
                let file = files[i];
                formData.set('file', file, file.fileName);
                const result = await this.uploadImageApi({ folder: 'banner-image', category: 'banner', data: formData });
                this.form.image = result.file_url;
            }
        },
        async deleteData() {
            try {
                this.isLoading = true;

                const response = await this.deleteBanner(this.form._id);

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('Deleted!');
                    this.getInitPageData();
                    this.hideModal('deleteModal');
                } else {
                    notificationDanger('Failed to delete!');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
    },
};
</script>
